<template>
  <sign-page
    title-text="专业技术职务聘任证书"
    :request="request"
    :column-list="columnList"
    :title-menus="[{key: 'add', label: '新增'}]"
    :dealFormData="dealFormData"
    @dataFileUpdate="dataFileUpdate"
    :form-parms-add="formParmsAdd"
    table-size="large"
    :tableActions="tableActions"
    :init-add-form-data="initAddFormData"
    :form-parms-update="formParmsUpdate"
    :tableActionsFixed="true"
    :data-file-parm="{pDirPath: '/worker_certificate/'}"
    :need-data-file="true">
    <fm-modal :mask-closable="false" v-model="modalXp" width="1000px" v-if="modalXp">
      <fm-title title-text="续聘记录" @clickTitleMenu="clickTitleMenu" :title-menus="[{key: 'add', label: '新增'}]"></fm-title>
      <fm-table-new
        @on-column-config-update="(columns) => $onTableColumnUpdate(columns, 'log')"
        :simple-filter="true"
        :toolbox="showToolBox"
        export-file-name="专业技术职务聘任证书续聘记录"
        :columns="columnsXp"
        border="row"
        :data-list="choose ? choose.updateLogList : []"
        :stripe="false"
        emptyPlaceholder="-">
        <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActionsXp" :row="row"></table-actions>
      </fm-table-new>
    </fm-modal>
    <fm-form-dialog
      :mask-closable="false"
      form-title="新增续聘记录"
      :open-dialog.sync="openDialogXp"
      :form-parms="formParmsAdd.filter(v => ['xlmc', 'mType', 'hirePro', 'hireWorker', 'hireStartDate', 'hireEndDate'].includes(v.key))"
      :old-data="chooseXp"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialogXp = false">
    </fm-form-dialog>
  </sign-page>
</template>

<script>
import TableActions from '@/components/base/TableActions'
import {
  workerCertificateRequest as request
} from '../../api'

import {
  dateOperating
} from '@/fmlib'

import {
  loadData,
  getSelectWorkerList,
  loadSelectWorkerList
} from '../../auth_lib'

export default {
  components: {TableActions},
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit) {
      this.$store.dispatch('loadJobTitleList')
      loadSelectWorkerList(this)
    }
  },
  computed: {
    jobTitleTypes () {
      return this.$store.getters.selectDatas['job_title_type']
    },
    jobTitleList () {
      return this.$store.getters.jobTitleList
    },
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    tableActionsXp () {
      return [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    baseColumnsXp () {
      return [{
        title: '专业',
        field: 'hirePro'
      },
      {
        title: '聘任职务',
        field: 'hireWorker'
      },
      {
        title: '岗位类型',
        field: 'xlmc'
      },
      {
        title: '岗位等级',
        field: 'mType'
      },
      {
        title: '聘期起始日期',
        field: 'hireStartDate',
        render: (h, rowData) => {
          return h('div', rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-')
        }
      },
      {
        title: '聘期终止日期',
        field: 'hireEndDate',
        render: (h, rowData) => {
          return h('div', rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '-')
        }
      },
      {
        title: '操作',
        slot: 'actions',
        search: false,
        export: false,
        configurable: false
      }]
    },
    columnsXp () {
      return this.$getTableConfig('log', this.baseColumnsXp)
    },
    tableActions () {
      let vm = this
      let data = [{
        key: 'xp',
        label: '续聘',
        dealFun: (pageVm, parm2) => {
          vm.pageVm = pageVm
          vm.choose = Object.assign({}, parm2)
          vm.modalXp = true
        }
      },
      {
        key: 'data_file',
        label: '扫描件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'select',
          selectDatas: getSelectWorkerList(this),
          label: '所属员工',
          key: 'workerId',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '专业',
          key: 'hirePro'
        },
        {
          type: 'input',
          label: '聘任单位',
          key: 'hireOrg'
        },
        {
          type: 'input',
          label: '聘任职务',
          key: 'hireWorker'
        },
        {
          type: 'select',
          label: '岗位类型',
          selectDatas: this.jobTitleTypes,
          key: 'xlmc'
        },
        {
          type: 'select',
          label: '岗位等级',
          getSelectDatas: (fData) => {
            let data = []
            if (fData && fData.xlmc) {
              data = this.jobTitleList.filter(v => v.data.type === fData.xlmc).map((v) => {
                return {
                  key: v.data.title,
                  label: v.data.title
                }
              })
            }
            return data
          },
          key: 'mType'
        },
        {
          type: 'datePicker',
          label: '聘期起始日期',
          key: 'hireStartDate'
        },
        {
          type: 'datePicker',
          label: '聘期终止日期',
          key: 'hireEndDate'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '证书编码',
          field: 'code',
          sort: true
        },
        {
          title: '员工姓名',
          field: 'workerName',
          sort: true
        },
        {
          title: '专业',
          field: 'hirePro',
          sort: true
        },
        {
          title: '聘任单位',
          field: 'hireOrg',
          sort: true
        },
        {
          title: '聘任职务',
          field: 'hireWorker',
          sort: true
        },
        {
          title: '岗位类型',
          field: 'xlmc',
          sort: true,
          filters: this.jobTitleTypes.map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '岗位等级',
          field: 'mType',
          filterMethod (parm) {
            return parm.row.mType === parm.value
          },
          sort: true
        },
        {
          title: '聘期起始日期',
          field: 'hireStartDate',
          sort: true,
          width: 200,
          dataType: Date,
          filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-'
          }
        },
        {
          title: '聘期终止日期',
          field: 'hireEndDate',
          sort: true,
          width: 200,
          dataType: Date,
          filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '-'
          }
        }]
        return data
      }
    }
  },
  methods: {
    timeBj (t1, t2) {
      return dateOperating.computeDay({days: 0, date: t1}) > dateOperating.computeDay({days: 0, date: t2})
    },
    dealFormData (data) {
      if (data.hireStartDate && data.hireEndDate && this.timeBj(data.hireStartDate, data.hireEndDate)) {
        this.$notice.info({
          title: '系统提示',
          desc: '聘期终止日期需要大于聘期起始日期。'
        })
        throw Error()
      }
      return data
    },
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'}),
        fileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该续聘记录吗?'})
      if (result) {
        this.choose.updateLogList = this.choose.updateLogList.filter((v) => this.chooseXp.lId !== v.lId)
        this.choose.updateLogList.forEach((v2, index2) => v2.lId = index2 + 1)
        await request.update(this.choose.id, {
          updateLog: JSON.stringify(this.choose.updateLogList)
        })
        await this.pageVm.loadData()
        this.choose = this.pageVm.dataList.find(v => v.id === this.choose.id)
      }
    },
    async formSubmit (data, resolve) {
      try {
        this.dealFormData(data)
      } catch (error) {
        resolve()
        return
      }
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
        }
      }
      if (data.lId !== null && data.lId !== undefined) {
        let i = 0
        while (i < this.choose.updateLogList.length) {
          if ((i + 1) === data.lId) {
            this.choose.updateLogList[i] = Object.assign({}, this.choose.updateLogList[i], data)
          }
          i += 1
        }
        await request.update(this.choose.id, {
          updateLog: JSON.stringify(this.choose.updateLogList)
        })
        await this.pageVm.loadData()
        this.choose = this.pageVm.dataList.find(v => v.id === this.choose.id)
      } else {
        for (let key in data) {
          if (data[key] instanceof Date) {
            data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
          }
        }
        data.lId = this.choose.updateLogList.length + 1
        this.choose.updateLogList.push(data)
        await request.update(this.choose.id, {
          updateLog: JSON.stringify(this.choose.updateLogList)
        })
        await this.pageVm.loadData()
        this.choose = this.pageVm.dataList.find(v => v.id === this.choose.id)
      }
      resolve()
      this.openDialogXp = false
    },
    tableAction (parm) {
      this.chooseXp = parm.data
      if (parm.action === 'edit') {
        this.openDialogXp = true
      } else if (parm.action === 'del') {
        this.delData()
      }
    },
    initAddFormData () {
      return {type: 'pro_hire'}
    },
    clickTitleMenu () {
      this.chooseXp = null
      this.openDialogXp = true
    },
    async loadData () {
      let parm = {
        type: 'pro_hire'
      }
      let data = await loadData(request, this, parm)
      data.forEach(v => {
        v.updateLogList = v.updateLog ? JSON.parse(v.updateLog) : []
        v.typeText = (this.$store.getters.certificateTypeList.find(i => i.key === v.type) || {label: v.type}).label
        v.imgStatusText = v.imgUploadTime ? '已上传' : '未上传'
      })
      return data
    }
  },
  data () {
    return {
      files: [],
      choose: null,
      loading: false,
      modalXp: false,
      src: null,
      chooseXp: null,
      openDialogXp: false,
      modal: false,
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
